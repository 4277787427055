import React from 'react';
import Helmet from 'react-helmet';
import imgFavicon from '../images/favicon.png';

export default ({ title }) => (
  <Helmet>
    <meta charSet="UTF-8" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1.0, viewport-fit=cover"
    />
    <meta name="msapplication-tap-highlight" content="no" />
    <title>{title}</title>
    <link
      href="https://fonts.googleapis.com/css?family=Dosis:400,500,600,800|Open+Sans"
      rel="stylesheet"
    />
    <link rel="icon" type="image/png" href={imgFavicon} />
  </Helmet>
);

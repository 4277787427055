import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';

const NotFoundPage = props => (
  <Layout>
    <SEO title="Shophilist App | 404: Not found" />
    <Content>
      <h1>NOT FOUND</h1>
      <p>You just found something that doesn&#39;t exist... the sadness.</p>
      <p>
        Remember this: <code>{props['*']}</code> is 404.
      </p>
      <Link to="/">Head to front page!</Link>
    </Content>
  </Layout>
);

const Content = styled.div`
  color: #fff6b9;
  background: #f1647d;
  padding: 2rem 3.7rem;
  flex: 1;

  a {
    transition: all 0.1s;
    display: inline-block;
    background: #fff6b9;
    padding: 10px;
    box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.1);
  }

  a:hover,
  a:focus {
    color: #f1647d;
    transform: translate(5px, 5px);
    box-shadow: 0px 0px 0 rgba(0, 0, 0, 0.1);
  }
`;

export default NotFoundPage;

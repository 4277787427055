import React from 'react';
import styled from 'styled-components';
import { Link as GatsbyLink } from 'gatsby';

const Footer = () => (
  <Content>
    <p>
      <Link to="/privacy/">Privacy Policy</Link>
      &ensp;&#8212;&ensp;
      <Link to="/story/">Story</Link>
      &ensp;&#8212;&ensp;
      <Link to="/support/">Support</Link>
    </p>
    <p>
      &copy; {new Date().getFullYear()}{' '}
      <a href="https://latehours.net">Latehours</a>
    </p>
    <Text>
      Google Play and the Google Play logo are trademarks of Google LLC.
    </Text>
    <Text>
      Apple and the Apple logo are trademarks of Apple Inc., registered in the
      U.S. and other countries. App Store is a service mark of Apple Inc.,
      registered in the U.S. and other countries.
    </Text>
  </Content>
);

const Content = styled.footer`
  position: relative;
  font-size: 0.9rem;
  color: #c0cace;
  background: #757b7e;
  padding: 2rem 3.7rem;

  &::after {
    pointer-events: none;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    transform: translateY(100vh);
    content: ' ';
    width: 100vw;
    height: 100vh;
    background: #757b7e;
  }

  a {
    color: #c0cace;
    &:visited {
      color: #c0cace;
    }
    &:hover {
      color: #edfaff;
    }
  }

  @supports (padding: max(0)) {
    padding-bottom: max(1rem, env(safe-area-inset-bottom));
  }
`;

const Text = styled.p`
  font-size: 0.7rem;
  margin-bottom: 1rem;
  line-height: 1rem;
  opacity: 0.6;
`;

const Link = styled(GatsbyLink)`
  color: #edfaff;
  &:visited {
    color: #edfaff;
  }
  &:hover {
    color: #ffffff;
  }
`;

export default Footer;
